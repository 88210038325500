<template>
    <div>
       <Relances :home="false" />
    </div>
</template>

<script>
import Relances from '@/components/stats/components/relances/relances.vue'

export default {
    components: {
        Relances
    }
}
</script>