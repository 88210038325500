<template>
    <div>{{ name }}</div>
</template>

<script>
import Utils from '@/assets/utils/utils'
import Member from '@/database/models/member'

export default {
    props: ['uid'],
    data() {
        return {
            name: ''
        }
    },
    mounted() {
        this.getContactName()
    },
    methods: {
        async getContactName() {
			let name = await Member.getMemory(this.uid, function(mbr)
            {
                if(mbr) return Utils.StrCapital(mbr.first_name+" "+mbr.last_name);
            });
			this.name = name
        },
    }
}
</script>