<template>
    <vs-col vs-align="center" vs-xs="12" vs-sm="12" vs-lg="12" class="py-3">

        <vx-card class="card_adjust">
            <vs-row v-for="m in fmotifs" :key="m.id">
                <vs-col vs-w="6">
                    <h3>{{m.desc}}</h3>
                </vs-col>
                <vs-col vs-w="1" vs-align="right">
                    <h4 class="text-primary align-right">{{(m.qty/total*100).toFixed() }} %</h4>
                </vs-col>
                <vs-col vs-w="5">
                    <div class="numbar" :style="{width: m.qty/total*100+'%'}"></div>
                </vs-col>
            </vs-row>
        </vx-card>
    </vs-col>
</template>

<style scoped>
.numbar {
    background-color: rgba(var(--vs-primary),1)!important;
    border-radius: 15px;
    height: 20px;
}
.align-right {
    text-align: right;
    padding-right: 5px;
}
</style>

<script>
export default {
    props: ['motifs'],
    computed: {
        fmotifs() {
            return this.motifs.filter( item => item.desc !== '' )
        },
        total() {
            let total = 0
            for (let m of this.motifs) total += m.qty
            return total
        }
    },
    methods: {
        getTotal() {
            
        },
    }
}
</script>